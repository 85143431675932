
import { Component, Vue } from "vue-property-decorator";
import Footer from "@/components/Footer.vue";
import Wiki from "@/components/Wiki.vue";
import { termsAndConditionsDefinition } from '../wiki-definitions/terms-and-conditions-definition';

@Component({
  name: "TermsAndConditions",
  components: {
    Footer,
    Wiki
  }
})
export default class TermsAndConditions extends Vue {
  public termsAndConditionsDef = termsAndConditionsDefinition;
}
