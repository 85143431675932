import { IWikiModel } from '@/interfaces/wiki-model';

export const termsAndConditionsDefinition: IWikiModel = {
   sections: [
       {
           id: 1,
           title: 'Termeni si condiții',
           text: '',
           subSections: [
               {
                   id: 1,
                   title: '1. TERMENI',
                   text: 'Prezentul site cuprinde: texte, poze, video-uri, logo-uri, documente privind funcționarea MOVKINETIC și constituie “continutul” \
                   site-ului <span class="text-primary">www.movkinetic.ro</span> care este proprietatea MOVKINETIC. Orice persoană care accesează acest site va trebui să citească acești Termeni \
                   și Condiții prezentați în continuare, înainte de accesarea site-ului. Parcurgerea și utilizarea site-ului <span class="text-primary">www.movkinetic.ro</span> reprezintă acceptarea fără \
                   limitări sau rezerve de către Dumneavoastră a prezentilor termeni și condiții. <span class="text-primary">www.movkinetic.ro</span> își rezervă dreptul, pe bază unilaterală, de a schimba, \
                   de a corecta, de a modifica, de a adăuga și/sau retrage în parte sau în întregime acești termeni, fără a anunța în prealabil. Aceste schimbări vor intra \
                   în vigoare din momentul publicării pe <span class="text-primary">www.movkinetic.ro</span>'
               },
               {
                   id: 2,
                   title: '2. DESCRIERE SITE',
                   text: '<span class="text-primary">www.movkinetic.ro</span> este un site web de prezentare a activităţii unei clinici de fizioterapie \
                   cu sediul în București. Acesta este gestionat de personalul MovKinetic.'
               },
               {
                   id: 3,
                   title: '3. DREPTURI DE AUTOR',
                   text: 'Întregul conținut al acestui site (date, imagini, texte, grafică, simboluri, elemente de grafică web, \
                    scripturi, programe și alte date) este proprietatea MOVKINETIC și este apărat de legea pentru protecția drepturilor \
                    de autor, toate drepturile fiind rezervate. Folosirea oricăror elemente enumerate mai sus fără obținerea unui acord \
                    prealabil de la deținătorii de drept se pedepsește conform legilor în vigoare. Conținutul site-ului <span class="text-primary">www.movkinetic.ro</span> nu \
                    poate fi reprodus, modificat, transferat, distribuit, republicat, copiat sau transmis în orice formă, incluzând forma electronică \
                    fără permisiunea anterioară scrisă a reprezentanților Movkinetic. '
               },
               {
                   id: 4,
                   title: '4. ABSOLVIREA DE RĂSPUNDERE',
                   text: 'MovKinetic actualizează acest site pentru a înlesni accesul public la informaţii privind activităţile sale. Administratorul \
                   depune eforturi pentru ca aceste informaţii să fie corecte şi oferite la timp. La semnalarea eventualelor erori, Administratorul va \
                   încerca în cel mai scurt timp, corectarea acestora. Informaţia oferită poate conţine legături web spre alte site-uri aspura conţinutului \
                   cărora MOVKinetic  nu deţine nici un control şi pentru care nu îşi asumă nici o responsabilitate. Un document disponibil pe site nu reproduce \
                   în mod obligatoriu textul unui document oficial.'
               },
               {
                   id: 5,
                   title: '5. DREPT APLICABIL',
                   text: 'Prezentii Termeni și condiții, precum și orice litigiu în legătură cu sau decurgând din clauzele acestor Termeni și condiții sau din accesarea \
                   / funcționarea / disfuncționalitatea site-ului se supun dreptului intern român. Limba Termenilor și condiții și a site-ului este limba română. \
                   În caz de litigiu, numai instanțele românești vor fi considerate competente. <br />  <br /> Utilizatorul site-ului <span class="text-primary">www.movkinetic.ro</span> confirmă că a citit și \
                   acceptat Termenii și Condițiile.'
               }
           ]
       }
   ]
};