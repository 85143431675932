import { render, staticRenderFns } from "./Wiki.vue?vue&type=template&id=20f495ea&scoped=true"
import script from "./Wiki.vue?vue&type=script&lang=ts"
export * from "./Wiki.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20f495ea",
  null
  
)

export default component.exports