
import { Component, Vue, Prop } from "vue-property-decorator";
import { IWikiModel } from "../interfaces/wiki-model";

@Component({
  name: "Wiki"
})
export default class Wiki extends Vue {
  @Prop() private model!: IWikiModel;
}
